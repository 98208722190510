<template>
  <app-dialog
    v-model="model"
    icon="ph:trash"
    :title="title"
    :width="600"
    @submit="onSubmit"
  >
    <template #footer>
      <div class="grid place-content-end">
        <app-button color="danger" :loading="status === 'pending'" type="submit"
          >Supprimer</app-button
        >
      </div>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import { useDialog } from "~/shared/dialog/composables/dialog.hook";
import type { OrganizationMemberView } from "~~/model/organization-member.model";

const properties = defineProps<{
  modelValue: boolean;
  organization: DatabaseTable<"organizations">;
  member: OrganizationMemberView;
}>();

const model = useVModel(properties, "modelValue");

const { close } = useDialog("OrganizationMemberDeleteDialog");

const title = computed(
  () =>
    `Supprimer ${properties.member.user.first_name} de ${properties.organization.name}`,
);

const { refresh, status, error } = useLazyFetch(
  `/api/organizations/${properties.organization.id}/members/${properties.member.id}/delete`,
  { method: "DELETE", immediate: false },
);

const { showError } = useToastNotification();

const onSubmit = async () => {
  await refresh();

  if (error.value?.statusText) return showError(error.value.statusText);

  return close({ confirmed: true });
};
</script>
